//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FacultyMixin from '@/mixins/faculty.js'
import { LOCALE_CODE_RUSSIAN } from '@/const/locale'
import { mapState } from 'vuex'
const Entities = require('html-entities').AllHtmlEntities

export default {
  name: 'NavigationSection',
  mixins: [FacultyMixin],
  data() {
    return {
      coding: [],
      math: [],
    }
  },
  computed: {
    ...mapState(['i18n']),
    isSnakeAllowed() {
      return this.i18n.locale === 'ru'
    },
    isCodingEnabled() {
      return this.isFacultyEnabled('coding')
    },
    isMathEnabled() {
      return (
        (this.isFacultyEnabled('math') &&
          this.$getTextMainSetting('branchLanguageCode') ===
            LOCALE_CODE_RUSSIAN) ||
        false
      )
    },
  },
  mounted() {
    const coding = []
    const math = []
    this.$getBullets('_courseCards').forEach((item) => {
      if (
        (item.is_active === '1' || item.is_active === 1) &&
        item.url !== '/math' &&
        item.url !== null
      ) {
        const temp = {}
        temp.title = Entities.decode(item.title)
        temp.name = this.getCourseName(item.url) || 'pytpro'
        temp.img = temp.name + '.png'
        if (
          (temp.name === 'pytstart' || temp.name === 'pytpro') &&
          !this.isSnakeAllowed
        ) {
          temp.img = temp.name + '-nosnake' + '.png'
        }
        temp.age = Entities.decode(item.childrens_age)
        temp.url = item.url
        temp.position = +item.position
        coding.push(temp)
      }
      if (
        (item.is_active === '1' || item.is_active === 1) &&
        item.url === '/math'
      ) {
        const temp = {}
        temp.title = Entities.decode(item.title)
        temp.name = item.code === 'math_junior' ? 'math-3-4' : 'math-5-6'
        temp.img = temp.name + '.png'
        temp.age = Entities.decode(item.childrens_age)
        temp.url = item.url
        temp.position = +item.position
        math.push(temp)
      }
    })
    const sortFunc = function (a, b) {
      return a.position > b.position ? 1 : b.position > a.position ? -1 : 0
    }

    this.coding = coding.sort(sortFunc)
    this.math = math.sort(sortFunc)
  },
  methods: {
    getCourseName(url) {
      if (url.includes('pc')) {
        return 'pc'
      }
      if (url.includes('junior')) {
        return 'junior'
      }
      if (url.includes('pytpro')) {
        return 'pytpro'
      }
      if (url.includes('pytstart')) {
        return 'pytstart'
      }
      if (url.includes('websites')) {
        return 'websites'
      }
      if (url.includes('gamedesign')) {
        return 'gamedesign'
      }
      if (url.includes('videocontent')) {
        return 'videocontent'
      }
      if (url.includes('graphicdesign')) {
        return 'graphicdesign'
      }
      if (url.includes('creative')) {
        return 'creative'
      }
      if (url.includes('unity')) {
        return 'unity'
      }
      if (url.includes('camp')) {
        return 'camp'
      }
      if (url.includes('frontend')) {
        return 'frontend'
      }
    },
    goToCourse(url) {
      window.location.href = this.localePath(url, this.$i18n.locale)
    },
  },
}
