export default {
  name: 'FacultyMixin',
  methods: {
    isFacultyEnabled(key) {
      const facultyUrls = new Map([
        ['math', '/math'],
        ['coding', '/coding'],
      ])
      const courses = this.$getCourses()[key]
      for (const i in courses) {
        if (
          courses[i].isActive === true &&
          facultyUrls.has(key) &&
          courses[i].urlPath === facultyUrls.get(key)
        ) {
          return true
        }
      }
      return false
    },
  },
}
